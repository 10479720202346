<template>
  <div>
    <member-menu/>
      <b-row>
        <b-col lg="12">
          <iq-card>
            <template v-slot:body>
              <div class="new-user-info">
                <b-row>
                  <b-form-group class="col-md-12" label="User ID:" label-for="Member ID:">
                    <b-form-input autofocus ref="inputUserId" @blur="autoFocus" @update="searchPhoneNumber"  v-model="memberId" type="text" placeholder="Scan Barcode"></b-form-input>
                  </b-form-group>
                  <div v-if="!finishSearch" class="text-center col-md-12">
                    <b-spinner small type="grow"></b-spinner>
                    Loading...
                  </div>
                  <div v-else-if="notFound && finishSearch" class="text-center col-md-12">
                    {{notFound}}
                  </div>
                  <!-- start Modal -->
                  <b-modal id="checkInModal" size="lg" title="Check In" hide-footer>
                    <b-row>
                      <b-col sm="12">
                        <iq-card body-class="profile-page p-0" class="mb-1">
                          <template v-slot:body>
                            <div class="profile-header">
                              <div class="profile-info p-2">
                                <b-row class="">
                                  <b-col lg="6"  class="user-detail pl-md-1">
                                    <div class="d-flex justify-content-sm-center align-items-center">
                                      <div class="pr-md-4">
                                        <b-img v-if="member.image" v-bind:src="member.profile" ref="file" alt="profile-img" fluid class="avatar-90" />
                                        <b-img v-else :src="profile" ref="file" alt="profile-img" fluid class="avatar-100"  />
                                        <input type="file" id="file" class="d-none position-absolute" accept="image/*" style="opacity: 0;" />
                                      </div>
                                      <div class=" d-flex align-items-center">
                                            <span>
                                           <h4>{{ member.name }}</h4>
                                            <h5 class="text-secondary"> {{memberId}} </h5>
                                              </span>
                                      </div>
                                    </div>
                                  </b-col>
                                  <b-col lg="6" xs="12" class="d-flex align-items-center justify-content-center mt-lg-0 mt-xs-3">
                                    <tab-nav :pills="true" id="pills-tab" class="profile-feed-items d-flex justify-content-center">
                                      <tab-nav-items :active="true" id="pills-packages" href="#packages" ariaControls="pills-packages" role="tab" :ariaSelected="true" title="Packages" />
                                      <tab-nav-items :active="false" id="pills-services" href="#services" ariaControls="pills-services" role="tab" :ariaSelected="false" title="Services" />
                                    </tab-nav>
                                  </b-col>
                                  <b-col lg="12" class="profile-center mt-4">
                                    <tab-content id="pills-tabContent-2">
                                      <tab-content-item :active="true" id="packages" aria-labelled-by="pills-feed-tab">
                                        <div class="iq-accordion career-style faq-style">
                                          <iq-card :class="`iq-card iq-accordion-block mb-2 ${index == 0 ? 'accordion-active' : ''}`" :id="item.id" v-for="(item,index) in member_package" :key="index" style="background-color: var(--iq-bg-light-color)">
                                            <div class="clearfix">
                                              <div class="container">
                                                <b-row class="py-1 cursor-crosshair" @click="onCollapse" :id="item.id" v-b-toggle="`accordian-${index}`">
                                                  <b-col sm="12"><a class="accordion-title"><span> {{ item.package_title }} </span></a></b-col>
                                                </b-row>
                                              </div>
                                            </div>
                                            <b-collapse :id="`accordian-${index}`" :visible="index == 0 ? true : false" accordion="my-accordion" role="tabpanel" class="accordion-details">
                                              <b-container>
                                                <b-list-group class="lists">
                                                  <span v-for="(i, key2) in item.service" :key="key2">
                                                    <b-list-group-item v-if="checkDayShift(item.shift) == 'No' || checkFreezing(item.freezing) == 'Yes'" class='border-1 border-danger my-1 d-flex cursor-not-allowed'>
                                                     <span class="d-flex w-100 justify-content-between text-danger">
                                                       <span> {{ i.service_title }} ({{i.available}})</span>
                                                       <span v-if="checkDayShift(item.shift) == 'No' && checkFreezing(item.freezing) == 'Yes'">Shift - Frezzing</span>
                                                       <span v-else-if="checkDayShift(item.shift) == 'No'">Shift</span>
                                                       <span v-else-if="checkFreezing(item.freezing) == 'Yes'">Frezzing</span>
                                                       <span><span class="text-success">Active till</span> {{item.period_expire_at}}</span>
                                                     </span>
                                                    </b-list-group-item>
                                                    <b-list-group-item  v-else :class="[{ 'active' : isSelectedPackages(i.id)},'border-0 my-1 d-flex cursor-pointer']" @click="checkPackages(i.id)">
                                                      <b-checkbox name="check-button" inline :value="i.id" v-model="packages_ids" @change="checkPackages(i.id)"></b-checkbox>
                                                     <span class="d-flex w-100 justify-content-between">
                                                       <span> {{ i.service_title }} ({{i.available}})</span>
                                                       <span><span class="text-success">Active till</span> {{item.period_expire_at}}</span>
                                                     </span>
                                                  </b-list-group-item>
                                                  </span>
                                                </b-list-group>
                                              </b-container>
                                            </b-collapse>
                                          </iq-card>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                          <b-button variant="primary" class="w-25" @click="checkInPackages">Confirm Packages</b-button>
                                        </div>
                                      </tab-content-item>
                                      <tab-content-item :active="false" id="services" aria-labelled-by="pills-activity-tab">
                                        <div class="accordion-details py-2 mb-2" style="background-color:var(--iq-bg-light-color)">
                                          <b-container>
                                            <b-list-group class="lists">
                                                  <span v-for="(i, key2) in member_Service" :key="key2">
                                                    <b-list-group-item v-if="checkDayShift(i.shift) == 'No' || checkFreezing(i.freezing) == 'Yes' || i.available == 0" class='border-1 border-danger my-1 d-flex cursor-not-allowed'>
                                                     <span class="d-flex w-100 justify-content-between text-danger">
                                                       <span> {{ i.package_title }} ({{i.available}})</span>
                                                       <span v-if="checkDayShift(i.shift) == 'No' && checkFreezing(i.freezing) == 'Yes' && i.available == 0">Shift - Frezzing - finish service</span>
                                                       <span v-else-if="checkDayShift(i.shift) == 'No'">Shift</span>
                                                       <span v-else-if="checkFreezing(i.freezing) == 'Yes'">Frezzing</span>
                                                       <span v-else-if="i.available == 0">finish service</span>
                                                       <span><span class="text-success">Active till</span> {{i.period_expire_at}}</span>
                                                     </span>
                                                    </b-list-group-item>
                                                    <b-list-group-item  v-else :class="[{ 'active' : isSelected(i.id)},'border-0 my-1 d-flex cursor-pointer']"  @click="check(i.id)">
                                                      <b-checkbox name="check-button" inline :value="i.id" v-model="services_ids" @change="check(i.id)"></b-checkbox>
                                                      <span class="d-flex w-100 justify-content-between">
                                                       <span> {{ i.package_title }} ({{i.available}})</span>
                                                       <span><span class="text-success">Active till</span> {{i.period_expire_at}}</span>
                                                     </span>
                                                  </b-list-group-item>
                                                  </span>
                                            </b-list-group>
<!--                                            <b-list-group class="lists">
                                              <b-list-group-item :class="[{ 'active' : isSelected(i.id)},'border-0 my-1 d-flex cursor-pointer']" v-for="(i, key2) in member_Service" :key="key2" @click="check(i.id)">
                                                <b-checkbox name="check-button" inline :value="i.id" v-model="services_ids" @change="check(i.id)"></b-checkbox>
                                                {{ i.package_title }}
                                              </b-list-group-item>
                                            </b-list-group>-->
                                          </b-container>
                                        </div>
                                        <div class="d-flex justify-content-center">
                                          <b-button variant="primary" class="w-25" @click="checkInServices">Confirm Service</b-button>
                                        </div>
                                      </tab-content-item>
                                    </tab-content>
                                  </b-col>
                                </b-row>
                              </div>
                            </div>
                          </template>
                        </iq-card>
                      </b-col>
                    </b-row>
                  </b-modal>
                  <!-- end Modal -->
                </b-row>
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col lg='12'>
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">Check In</h4>
            </template>
            <template v-slot:body>
              <div v-if="loadTableVisits" class="text-center">
                <b-spinner small type="grow"></b-spinner>
                Loading...
              </div>
              <b-table v-else striped responsive :fields="columns" :items="AllVisits" class="mb-0 table-borderless">
                <template v-slot:cell(member_name)="data">
                  {{data.item.member.name}}
                </template>
                <template v-slot:cell(member_id)="data">
                  {{data.item.member.membership_id}}
                </template>
              </b-table>
              <b-pagination v-model="currentPageVisits"
                            class="mt-3"
                            :total-rows="totalVisits"
                            align="right"
                            :per-page="per_pageVisits"
                            aria-controls="my-table"
                            @input="changeContentVisit"
              ></b-pagination>
            </template>
          </iq-card>
        </b-col>
      </b-row>
  </div>
</template>

<script>
import { core } from '../../config/pluginInit'

export default {
  name: 'checkInOut',
  data () {
    return {
      currentPageVisits: 1,
      per_pageVisits: 10,
      totalVisits: 6,
      loadTableVisits: true,
      AllVisits: [],
      member: {},
      profile: require('../../assets/images/user/default.png'),
      finishSearch: true,
      memberId: '',
      notFound: '',
      showPopup: false,
      member_package: [],
      member_Service: [],
      ids: [],
      pIds: [],
      openPackageId: '',
      allDetailsServicePackage: [],
      services_ids: [],
      packages_ids: [],
      columns: [
        { label: 'Member Name', key: 'member_name', class: 'text-left' },
        { label: 'Member ID', key: 'member_id', class: 'text-left' },
        { label: 'Check in Time', key: 'check_in', class: 'text-left' },
        { label: 'Check out time', key: 'check_out', class: 'text-left' }
      ]
    }
  },
  methods: {
    autoFocus () {
      this.$refs.inputUserId.focus()
    },
    tConvert (time) {
      time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/)
      if (time.length > 1) {
        time = time.slice(1)
        time[5] = +time[0] < 12 ? 'AM' : 'PM'
        time[0] = +time[0] % 12 || 12
      }
      time.splice(3, 1, ' ')
      return time.join('')// return adjusted time or original string
    },
    searchPhoneNumber () {
      this.finishSearch = false
      this.$store.dispatch('searchByMemberID', this.memberId).then(res => {
        this.member = res.data.data.member
        var all = res.data.data.all.map(v => ({ ...v, active: false }))
        this.member_package = all.filter(function (itm) {
          return itm.type === 'package'
        })
        this.openPackageId = this.member_package[0].id
        this.member_Service = all.filter(function (itm) {
          return itm.type === 'service'
        })
        this.finishSearch = true
        this.$bvModal.show('checkInModal')
        this.notFound = false
        // this.minimizeServicesInPackages()
      }).catch(err => {
        this.finishSearch = true
        this.notFound = err.response.data.error
      })
    },
    minimizeServicesInPackages () {
      // eslint-disable-next-line no-unused-vars
      var allServicesInSpacificPackage = []
      this.member_package.forEach(hallObject => {
        hallObject.service.forEach(allService => {
          const index = allServicesInSpacificPackage.findIndex(serviceIndex => serviceIndex.service_id === allService.service_id)
          if (index <= -1) {
            console.log('m4 mogod')
            allServicesInSpacificPackage.push({ ...allService, count: 1 })
          } else {
            console.log('mogod')
            allServicesInSpacificPackage[index].count += 1
          }
        })
        hallObject.service = allServicesInSpacificPackage
        allServicesInSpacificPackage = []
      })
      console.log('this.member_package', this.member_package)
    },
    onCollapse (e) {
      const activeEl = document.getElementsByClassName('accordion-active')[0]
      activeEl.classList.remove('accordion-active')
      const el = e.target.closest('.iq-accordion-block')
      el.classList.add('accordion-active')
      console.log('el', el)
      this.openPackageId = el.id
    },
    checkPackages (id) {
      if (this.packages_ids.includes(id)) {
        // eslint-disable-next-line eqeqeq
        var position = this.packages_ids.findIndex(i => i == id)
        this.packages_ids.splice(position, 1)
        // eslint-disable-next-line eqeqeq
        var positionPackageInDetails = this.allDetailsServicePackage.findIndex(element => element.package_id == this.openPackageId)
        console.log('pooooooooostion', positionPackageInDetails)
        // eslint-disable-next-line eqeqeq
        var isFoundService = this.allDetailsServicePackage.find(element => element.package_id == this.openPackageId)
        // eslint-disable-next-line eqeqeq
        var positionServiceIdInPackges = isFoundService.service_id.findIndex(el => el == id)
        isFoundService.service_id.splice(positionServiceIdInPackges, 1)
        console.log('isFoundService.service_id.length', isFoundService.service_id.length)
        // eslint-disable-next-line eqeqeq
        if (isFoundService.service_id.length == 0) {
          this.allDetailsServicePackage.splice(positionPackageInDetails, 1)
        }
      } else {
        this.packages_ids.push(id)
        // eslint-disable-next-line eqeqeq
        var isFound = this.allDetailsServicePackage.find(element => element.package_id == this.openPackageId)
        if (isFound) {
          isFound.service_id.push(id)
        } else {
          this.allDetailsServicePackage.push({
            package_id: this.openPackageId,
            service_id: [id]
          })
        }
      }
    },
    isSelectedPackages (id) {
      if (this.packages_ids.includes(id)) {
        return true
      } else {
        return false
      }
    },
    check (id) {
      if (this.services_ids.includes(id)) {
        // eslint-disable-next-line eqeqeq
        var position = this.ids.findIndex(i => i == id)
        this.services_ids.splice(position, 1)
      } else {
        this.services_ids.push(id)
      }
    },
    isSelected (id) {
      if (this.services_ids.includes(id)) {
        return true
      } else {
        return false
      }
    },
    checkInServices () {
      this.$store.dispatch('checkInServices', { id: this.member.id, services_ids: this.services_ids }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('checkInModal')
      }).catch(err => {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(err.response.data.errors)) {
          core.showSnackbar('error', value)
          // test
        }
      })
    },
    checkInPackages () {
      this.$store.dispatch('checkInPackages', { id: this.member.id, data: this.allDetailsServicePackage }).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$bvModal.hide('checkInModal')
      }).catch(err => {
        // eslint-disable-next-line no-unused-vars
        for (const [key, value] of Object.entries(err.response.data.errors)) {
          core.showSnackbar('error', value)
        }
      })
    },
    checkFreezing (frezzing) {
      if (frezzing == null) {
        return 'No'
      } else {
        return 'Yes'
      }
    },
    checkDayShift (arrayOfShift) {
      var arrayOfWeekdays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
      var dateObj = new Date()
      var weekdayNumber = dateObj.getDay()
      var weekdayName = arrayOfWeekdays[weekdayNumber]
      if (arrayOfShift) {
        if (arrayOfShift.days.includes(weekdayName)) {
          // eslint-disable-next-line no-unused-vars
          var hour = new Date().getHours()
          // eslint-disable-next-line no-unused-vars
          var minutes = new Date().getMinutes()
          if (hour >= arrayOfShift.start.slice(0, 2).trim() && hour <= arrayOfShift.end.slice(0, 2).trim()) {
            // eslint-disable-next-line no-unreachable,eqeqeq
            if (hour == arrayOfShift.start.slice(0, 2).trim() || hour == arrayOfShift.end.slice(0, 2).trim()) {
              if (minutes >= arrayOfShift.start.slice(3, 5).trim() && minutes <= arrayOfShift.end.slice(3, 5).trim()) {
                return 'Yes'
              } else {
                return 'No'
              }
            } else {
              return 'Yes'
            }
          } else {
            return 'No'
          }
        } else {
          return 'No'
        }
      } else {
        return 'Yes'
      }
    },
    getVisitReport () {
      this.loadTablegetAllVisits = true
      this.$store.dispatch('getAllVisits', {
        pageNumber: this.visiteCurrentPage,
        memberId: this.$route.params.id
      }).then(res => {
        this.AllVisits = res.data.data
        this.visiteCurrentPage = res.data.meta.current_page
        this.visiteper_page = res.data.meta.per_page
        this.visitetotal = res.data.meta.total
        this.loadTableVisits = false
      })
    },
    changeContentVisit () {
      this.getVisitReport()
    }
  },
  mounted () {
    this.autoFocus()
  },
  created () {
    this.getVisitReport()
  }
}
</script>
<style>

</style>
